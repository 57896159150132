import * as React from "react"
import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Seo from '../components/seo'
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import WhoWeAre from "../components/WhoWeAre"
import TableCompetitions from "../components/TableCompetitions"
import ComboWrapperProcurement from "../components/ComboWrapperProcurement"
export default function Competitions(pageData) {
  const data = pageData?.data
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri
  return (
    <>
      <Seo data={seo}/>
      <Layout translatedURI={translatedURI}>
        <WhoWeAre
          title={data.wpPage.pageSubtitle?.subtitle}
          name={data.wpPage?.title}
          description={data.wpPage.pageDescription?.pageDescription}
          data={data?.wpPage}
        />

      <ComboWrapperProcurement data={data.wpPage.CompetitionsPage} locale={locale}/>

       

      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query competitions($databaseId: Int) {
    wpPage(databaseId: { eq: $databaseId }) {
      id
      title
      pageSubtitle {
        fieldGroupName
        subtitle
      }
      content
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      pageDescription {
        pageDescription
      }
    
      CompetitionsPage {
        headerText {
          projectName
          submittingDate
          deadlineForSubmitting
          competitionLink
          status
          contactEmail
          projectBrief
          supplierNote
          deadline
          location
        }
        
        competitions {
          projectName
          submittingDate
          deadlineForSubmitting
          status
          contactEmail
          projectBrief
          supplierNote
          deadline
          locationText
          locationLink
          competitionFiles{
            fileName
            file{
              localFile {
                publicURL
              }
            }
          }
        }
        apiUrl
        
      }
    }
  
  }
`
